.auth-layout {
  min-height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .input-auth {
    margin-top: 20px;
    height: 40px;
  }

  .ant-btn, .ant-btn:active, .ant-btn:focus {
    border-radius: 10px;
  }
}

@primary-color: #228B90;@disabled-color: rgba(0, 0, 0, 0.7);