@normal-text: #ffffff;
@color-selected: #ffffff;
@bg-selected: #228B90;
@corlor-sub: #D2D2D2;
@bg-submenu: #228B90;
.sidebar {
    .ant-menu-item-active, .ant-menu-submenu-active{
        color: @normal-text;
    }
    .ant-menu-item-selected {
        color: @color-selected  !important;
        background-color: @bg-selected  !important;
    }
    .ant-menu-submenu-open {
        .ant-menu-item-selected {
            color: @normal-text  !important;
            background-color: @bg-selected !important;
        }
        .ant-menu-item-only-child {
            color: @corlor-sub;
            background-color: @bg-submenu;
        }
    }
    .ant-menu-submenu-selected {
        color: @normal-text;
        background-color: @bg-submenu;
    }
    .ant-menu-submenu > .ant-menu {
        background-color: @bg-submenu;
    }
    .ant-menu-submenu :hover {
        color: @normal-text;
    }
}

@primary-color: #228B90;@disabled-color: rgba(0, 0, 0, 0.7);