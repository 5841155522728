@import '~antd/dist/antd.less';

body,
#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout-sider {
  background-color: #228B90;
}

.ant-table-thead>tr>th {
  background-color: #228B90;
  color: #F6F6F6;
  text-align: center;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  border-radius: 5px;
}

.ant-modal-header {
  padding: 0;
  border-radius: 5px 5px 0 0;
}

.ant-picker {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.ant-form label,
.ant-radio-wrapper {
  margin-right: 32px;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 32px;
}

.ant-table.ant-table-small .ant-table-thead>tr>th {
  background-color: #228B90;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}

.aiu-title {
  width: 100%;
  min-height: 48px;
  padding: 12px;
  background-color: #228B90;
  color: #F6F6F6;
  border: 1px solid #E8E8E8;
}

.aiu-content {
  width: 100%;
  min-height: 48px;
  padding: 12px;
  color: #595959;
  border: 1px solid #E8E8E8;
}
@primary-color: #228B90;@disabled-color: rgba(0, 0, 0, 0.7);